import React, { useState, useEffect } from "react"
import _ from "lodash"
import data from "../../../../site-metadata.json"

const URL = "https://ip.nf/me.json"

export default props => {
  const [checked, setChecked] = useState(false)
  const [info, setInfo] = useState({ ip: "" })

  const handleClick = () => setChecked(!checked)

  useEffect(() => {
    fetch(URL, { method: "get" })
      .then(response => response.json())
      .then(data => {
        setInfo({ ...data })
      })
  }, [])

  return (
    <div>
      <div class="sm:absolute sm:w-80 mt-4 sm:mt-1 sm:text-center top-6 left-0 right-0 z-0 m-auto">
        <h2 class="card-title text-base-content font-bold text-2xl sm:text-2xl mb-0">
          Offer for {props.title}
        </h2>
      </div>
      <div className="mt-8">
        <form
          action="https://submit-form.com/BSk5Mudh"
          method="POST"
          className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6"
        >
          <input
            type="hidden"
            value={_.get(data, "domain", null) + "/success"}
            name="_redirect"
          />
          {/*Domain Hidden*/}
          <input type="hidden" value={props.postTitle} name="domain" />
          {/*IP Hidden*/}
          <input type="hidden" value={info.ip.country} name="Country" />
          <input type="hidden" value={info.ip.city} name="City" />
          <input type="hidden" value={info.ip.ip} name="IP:" />
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700 text-base-content"
            >
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="name"
                id="name"
                autocomplete="given-name last_name"
                className="input input-bordered w-full text-base-content"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700 text-base-content"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="email"
                id="email"
                autocomplete="email"
                className="input input-bordered w-full text-base-content"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700 text-base-content"
            >
              Phone
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="phone_number"
                id="phone_number"
                autocomplete="tel"
                className="input input-bordered w-full text-base-content"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700 text-base-content"
            >
              Offer
            </label>
            <div className="mt-1">
              <input
                type="number"
                id="offer"
                placeholder="USD"
                name="offer"
                className="input input-bordered w-full text-base-content"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700 text-base-content"
            >
              Message
            </label>
            <div className="mt-1">
              <textarea
                style={{ height: "4.5rem" }}
                id="message"
                name="message"
                rows="4"
                className="input input-bordered w-full text-base-content"
              ></textarea>
            </div>
          </div>
          <div className="sm:col-span-2">
            <div class="inline-flex space-x-1.5 items-center justify-start">
              <input
                type="checkbox"
                class="checkbox checkbox-md"
                required
                onClick={handleClick}
                checked={checked}
              />
              <div className="ml-3">
                <p className="text-sm sm:text-base text-gray-500 dark:text-base-content">
                  I agree to the{" "}
                  <a
                    href="/terms"
                    className="font-medium text-gray-700 text-base-content underline"
                  >
                    Terms
                  </a>{" "}
                  &{" "}
                  <a
                    href="/privacy"
                    className="font-medium text-gray-700 text-base-content underline"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2">
            <button type="submit" className="btn btn-primary w-full">
              Send Offer
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
