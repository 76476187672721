import React, { useEffect, Fragment } from "react"

export default () => {
  useEffect(() => {
    // This runs the script
    setTimeout(() => {
      require("../helpers/chat")
    }, 5000)
  }, [])
  return <Fragment />
}
