import React, { Fragment } from "react"
import PriceBox from "./PriceBox"
import PriceBoxMobile from "./PriceBoxMobile"

export default props => {
  const isBrowser = typeof window !== "undefined"
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  React.useEffect(() => {
    if (!isBrowser) return false
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return (
    <Fragment>
      {width < 640 ? (
        <PriceBoxMobile
          dan={props.dan}
          offer={props.offer}
          escrow={props.escrow}
          afternic={props.afternic}
          font={props.font}
          title={props.title}
          price={props.price}
          description={props.description}
          possible_uses={props.possible_uses}
          logo={props.logo}
        />
      ) : (
        <PriceBox
          dan={props.dan}
          offer={props.offer}
          escrow={props.escrow}
          afternic={props.afternic}
          font={props.font}
          title={props.title}
          price={props.price}
          description={props.description}
          possible_uses={props.possible_uses}
          logo={props.logo}
        />
      )}
    </Fragment>
  )
}
