import React from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "../components/themeContext"
import PriceBox from "@components/PriceBox"
import Benefits from "@components/Benefits"
import Header from "@components/Header"
import HeaderPromotion from "../components/HeaderPromotion"
import Footer from "@components/Footer"
import Layout from "@components/Layout"
import FaqOnPost from "@components/FaqOnPost"
import SimilarProducts from "@components/SimilarProducts"
import Mockup from "@components/Mockup"
import LoadChat from "@components/Chat"

export default function post(props) {
  const { data } = props
  const {
    title,
    price,
    logo,
    font,
    possible_uses,
    Afternic,
    Dan,
    Escrow,
    Make_Offer,
    description,
  } = data.item.data

  return (
    <ThemeProvider>
      <LoadChat />
      <Layout>
        <div class="z-50">
          <HeaderPromotion />
        </div>
        <Header />
        <div class="px-4 sm:px-0">
          <PriceBox
            font={font}
            title={title}
            price={price}
            afternic={Afternic}
            offer={Make_Offer}
            dan={Dan}
            escrow={Escrow}
            description={description}
            possible_uses={possible_uses}
            logo={
              logo ? logo.localFiles[0].childImageSharp.gatsbyImageData : null
            }
          />
          <Mockup
            logo={
              logo ? logo.localFiles[0].childImageSharp.gatsbyImageData : null
            }
          />
          <Benefits />
          <FaqOnPost />
          <div class="container my-12 mx-auto max-w-6xl 2xl:max-w-7xl">
            <p class="text-3xl font-extrabold leading-10 text-base-content">
              Similar Products
            </p>
            <div class="flex flex-wrap mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8">
              <SimilarProducts />
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $dateFormat: String) {
    item: airtable(data: { slug: { eq: $slug } }) {
      data {
        title
        price
        font
        slug
        Afternic
        Escrow
        Make_Offer
        Dan
        possible_uses
        description
        date(formatString: $dateFormat)
        logo {
          localFiles {
            id
            url
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`
