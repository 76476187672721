import React from "react"
import { useCart } from "react-use-cart"
import { FaHeart } from "react-icons/fa"

export default function Items(props) {
  const { addItem, inCart, removeItem } = useCart()

  const products = [
    {
      id: props.name,
      name: props.name,
      price: props.price,
      slug: props.slug,
    },
  ]
  return (
    <div>
      {products.map(p => {
        const alreadyAdded = inCart(p.id)
        return (
          <div key={p.id}>
            {!alreadyAdded && (
              <div onClick={() => addItem(p)}>
                <div>
                  <FaHeart />
                </div>
              </div>
            )}
            {alreadyAdded && (
              <div onClick={() => removeItem(p.id)}>
                <div>
                  <FaHeart class="fill-current text-secondary" />
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
