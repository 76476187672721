import React, { useState, Fragment } from "react"

export default props => {
  const [LazyComponent, setLazyComponent] = useState(null)
  const [render, setRender] = useState(true)
  const loadLazyComponent = async () => {
    if (LazyComponent !== null) {
      return
    }

    // import default export of `lazy.js` module
    const { default: lazyModule } = await import("@components/Speech")
    // create React component so it can be rendered
    const LazyElement = React.createElement(lazyModule, {
      // pass props to component here
      title: props.title,
    })
    setLazyComponent(LazyElement)
  }

  const handleClick = () => {
    loadLazyComponent()
    setRender(!render)
    setTimeout(() => {
      setRender(true)
    }, 50)
  }

  return (
    <Fragment>
      <div
        className="w-12 h-12 p-2 btn btn-large flex-shrink-0 text-gray-400 dark:bg-base-300"
        onClick={handleClick}
      >
        <svg
          data-todo-x-description="Heroicon name: outline/play"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="white"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </div>
      <Fragment>{render && <>{LazyComponent}</>}</Fragment>
    </Fragment>
  )
}
