import React from "react"

export default () => {
  return (
    <div class="max-w-6xl 2xl:max-w-7xl m-auto">
      <p class="relative text-3xl font-extrabold leading-10 pb-8 text-base-content">
        What's Next
      </p>
      <div class="card shadow-xl p-8 bg-base-100">
        <ul class="w-full steps">
          <div class="step step-primary text-xl p-2 mb-8">
            <li class="font-bold mt-2 text-base-content">Checkout Securely</li>
            <p class="text-base text-gray-400 p-4 h-40 sm:h-32 sm:h-auto overflow-hidden">
              We accept all major credit cards, processed with Snipcart or
              Escrow transaction
            </p>
          </div>
          <div class="step step-primary text-xl p-2 mb-8">
            <li class="font-bold mt-2 text-base-content">
              Follow Transfer Guidance
            </li>
            <p class="text-base text-gray-400 p-4 h-40 sm:h-32 sm:h-auto overflow-hidden">
              We email you the domain transfer instructions and your unique
              authorization code
            </p>
          </div>
          <div class="step step-primary text-xl p-2 mb-8">
            <li class="font-bold mt-2 text-base-content">Receive domain</li>
            <p class="text-base text-gray-400 p-4 h-40 sm:h-32 sm:h-auto overflow-hidden">
              Receive complete ownership of the domain name.
            </p>
          </div>
        </ul>
      </div>
    </div>
  )
}
