import React, { useState } from "react"
import Divider from "@components/Divider"
import DanButton from "../PaymentPlan/PayButtons/DanButton"
import AfternicButton from "../PaymentPlan/PayButtons/AfternicButton"
import EscrowButton from "../PaymentPlan/PayButtons/EscrowButton"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Price from "@components/Price"

export default props => {
  const [checked, setChecked] = useState(false)
  const handleClick = () => setChecked(!checked)
  return (
    <div>
      <div class="flex-row items-center space-x-4 card-body pt-0 2xl:pt-2 pb-4 px-0">
        <div class="flex-1">
          <h2 class="card-title font-bold text-base sm:text-xl mb-0 text-base-content">
            Buy {props.title}
          </h2>{" "}
          {props.price && (
            <h2 class="text-base-content font-semibold text-2xl sm:text-3xl text-base-content">
              <Price currencyLabel={false} postPrice={props.price} />
            </h2>
          )}
        </div>{" "}
        <div class="flex-0 pr-2 h-24">
          <div class="avatar absolute top-10 right-6 sm:right-10">
            <div class="mb-8 logo rounded-box w-24 h-24 ring ring-primary light:ring-offset-base-100 ring-offset-2">
              <Img
                style={{ width: "144px", left: "-25%", top: "5%" }}
                alt="image"
                objectFit="cover"
                image={props.logo}
              />
            </div>
          </div>
        </div>
      </div>
      <form>
        <div class="inline-flex space-x-1.5 items-center justify-start mb-4">
          <input
            type="checkbox"
            class="checkbox checkbox-md"
            required
            onClick={handleClick}
            checked={checked}
          />
          <div className="ml-3">
            <p className="text-sm sm:text-base text-gray-500 dark:text-base-content">
              I agree to the{" "}
              <a
                href="/terms"
                className="font-medium text-gray-700 text-base-content underline"
              >
                Terms
              </a>{" "}
              &{" "}
              <a
                href="/privacy"
                className="font-medium text-gray-700 text-base-content underline"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
        <div>
          {!checked ? (
            <button
              type="submit"
              className="btn btn-primary w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Pay <Price currencyLabel={false} postPrice={props.price} /> by
              Card
            </button>
          ) : (
            <button
              type="submit"
              href="#"
              className="snipcart-add-item btn btn-primary w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              data-item-id={props.title}
              data-item-name={props.title}
              data-item-price={props.price}
              data-item-max-quantity="1"
              data-item-url={`/`}
            >
              Pay <Price currencyLabel={false} postPrice={props.price} /> by
              Card
            </button>
          )}
        </div>
      </form>
      <Divider />
      {props.dan && (
        <div>
          <DanButton price={props.price} postTitle={props.title} />
        </div>
      )}
      {props.afternic && (
        <div>
          <AfternicButton price={props.price} postTitle={props.title} />
        </div>
      )}
      {props.escrow && (
        <div>
          <EscrowButton price={props.price} postTitle={props.title} />
        </div>
      )}
    </div>
  )
}
