import React from "react"
import "./html-button.css"

export default function DanButton(props) {
  const numberFormat = value =>
    new Intl.NumberFormat("en-US", {
      currency: "USD",
    }).format(value)
  const DanActive = () => {
    return {
      __html: `<a class="btn btn-accent shadow hover:bg-gray-50 w-full" href="https://dan.com/orders/checkout/${
        props.postTitle
      }?buy_in_installments=yes&utm_campaign=BuyInInstallments&utm_medium=cta&utm_source=dan.com&number_of_installments=${
        props.currentIndex
      }" target="_blank">Pay $${numberFormat(
        Math.round(props.price)
      )} with<div class="w-24 ml-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 30"><path d="M18.4 7.7V.3h7.3v7.3h-7.3zM33.1 26c0 2-1.6 3.7-3.7 3.7-2 0-3.7-1.6-3.7-3.7 0-2 1.6-3.7 3.7-3.7 2 0 3.7 1.7 3.7 3.7zm-14.7-7.3V15h-3.7c-2 0-3.7 1.6-3.7 3.7 0 2 1.6 3.7 3.7 3.7 2-.1 3.7-1.7 3.7-3.7zm0-11V15h7.3v3.7c0 6.1-4.9 11-11 11s-11-4.9-11-11 4.9-11 11-11h3.7z" fill-rule="evenodd" clip-rule="evenodd" fill="#008c80"></path><path d="M40.9 10.3h2.6c1.4 0 2.6.2 3.8 1 1.2.9 1.9 2.4 1.9 4.1 0 1.7-.7 3.1-2 4.1-1.2.8-2.3 1-3.7 1h-2.6V10.3zm2 1.7v6.7h.6c.5 0 1.6 0 2.5-.7.8-.6 1.3-1.6 1.3-2.7s-.4-2.1-1.2-2.7c-.8-.6-1.8-.7-2.5-.7-.1.1-.7.1-.7.1zm16.6 8.5l-.9-2.1h-4.1l-.9 2.1h-2.1l4.4-10.1h1.5l4.2 10.1h-2.1zm-4.3-3.8h2.9l-1.4-3.6-1.5 3.6zm12 3.8h-2V10.3h1.4l6.2 6.8v-6.8h2v10.1h-1.4l-6.2-6.8v6.9zm11.1-1c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.6-1.2-1.2zm13.8-1.7v2.3c-.6.3-1.4.6-2.5.6-1.8 0-2.9-.6-3.7-1.4-1.2-1.1-1.6-2.4-1.6-3.9 0-1.8.8-3.2 1.6-4 1-1 2.3-1.3 3.7-1.3.7 0 1.6.1 2.5.6V13c-.9-1.1-2.1-1.1-2.4-1.1-2 0-3.4 1.7-3.4 3.5 0 2.2 1.7 3.5 3.4 3.5 1 0 1.8-.4 2.4-1.1zm9.1-7.7c3.1 0 5.3 2.2 5.3 5.3 0 3.1-2.2 5.3-5.3 5.3-3.1 0-5.3-2.2-5.3-5.3 0-3.1 2.2-5.3 5.3-5.3zm0 8.6c1.9 0 3.2-1.4 3.2-3.3s-1.3-3.3-3.2-3.3c-1.9 0-3.2 1.4-3.2 3.3 0 2 1.3 3.3 3.2 3.3zm8.1 1.8l1.9-10.1h1.4l2.7 6.4 2.8-6.4h1.4l1.7 10.1h-2l-.9-6.2-2.7 6.2h-.6l-2.6-6.2-1 6.2h-2.1z" fill="#444"></path></svg></div></a>`,
    }
  }

  return <div dangerouslySetInnerHTML={DanActive()}></div>
}
