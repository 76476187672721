import React from "react"
import "./html-button.css"

export default function AfternicButton(props) {
  const numberFormat = value =>
    new Intl.NumberFormat("en-US", {
      currency: "USD",
    }).format(value)
  const AfternicActive = () => {
    return {
      __html: `<a class="btn btn-secondary btn-outline mt-4 shadow flex hover:bg-gray-50 w-full" href="https://www.afternic.com/domain/${
        props.postTitle
      }"target="_blank"><?xml version="1.0" encoding="utf-8"?>
Pay $${numberFormat(Math.round(props.price))} with
<div class="w-24 ml-2"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 125 30" style="enable-background:new 0 0 125 30;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#EF3E42;}
	.st1{fill:#1C2674;}
	.st2{fill:#6CAB36;}
	.st3{fill:#FFFFFF;}
</style>
<g transform="matrix(.34342548 0 0 .34342548 2.298922 31.484589)">
	<path class="st0" d="M219-75.3c-2.7-2.4-6.7-2.4-9.3,0c-1.4,1.3-2.1,3.1-2,4.9c-0.1,1.8,0.7,3.6,2,4.9c2.7,2.4,6.7,2.4,9.3,0
		c1.3-1.2,2.1-3,2-4.9C221.1-72.3,220.4-74.1,219-75.3"/>
	<g>
		<path class="st1" d="M8-34.8c0.8-0.4,1.6-0.6,2.5-0.8c0.9-0.1,1.9-0.2,2.8-0.2c1,0,1.9,0.1,2.9,0.2c0.2,0,0.6,0.1,1,0.2v8.7
			c-1.7,0.2-3.4,0.2-5.1,0.2c-1.7,0.1-3.4-0.3-4.9-1.1c-0.6-0.4-1.4-1.2-1.4-3.7c0-0.8,0.2-1.5,0.6-2.2C6.8-34,7.4-34.5,8-34.8
			 M25.1-56.1c-1.5-1.6-3.3-2.8-5.4-3.5c-2.6-0.9-5.3-1.3-8-1.2c-2.5,0-4.9,0.2-7.3,0.6c-1.8,0.3-3.5,0.7-5.3,1.3l-0.8,0.3l0.1,0.8
			l1.1,7.4l0.2,1.2l1.2-0.4c1.4-0.5,2.7-0.8,4.2-1C7-50.9,8.8-51,10.7-51c1.2,0,2.4,0.1,3.5,0.5c0.8,0.3,1.5,0.8,2,1.4
			c0.5,0.6,0.8,1.4,0.9,2.2c0.1,0.5,0.1,1.1,0,1.7c0,0.4-0.1,0.7-0.1,1.1v0.1c-0.5-0.1-1.1-0.2-1.8-0.2l-0.3,0c-1-0.1-2-0.2-2.9-0.2
			c-2.2,0-4.4,0.2-6.5,0.7c-2,0.4-3.9,1.2-5.6,2.3c-1.6,1.1-3,2.5-3.9,4.2c-1,1.9-1.5,4.1-1.5,6.3c-0.1,2.2,0.4,4.4,1.3,6.5
			c0.8,1.7,2.1,3.2,3.6,4.3c1.6,1.1,3.5,2,5.4,2.4c2.2,0.5,4.5,0.7,6.8,0.7c3.3,0,6.7-0.2,10-0.7c1.6-0.2,2.9-0.5,4-0.7
			c0.9-0.2,1.7-0.3,2.6-0.4l0.9-0.1v-25c0-2.3-0.4-4.5-1-6.7C27.6-52.7,26.6-54.6,25.1-56.1"/>
		<path class="st1" d="M57.4-78.4c-1.9-0.4-3.9-0.6-5.9-0.6c-5.9,0-10.5,1.6-13.3,4.7c-3.1,3.5-4.8,8.1-4.7,12.7v44.3h12.1v-32.3
			h14.1v-10.1H45.6v-1.8c0-1.1,0.1-2.2,0.3-3.3c0.2-0.9,0.5-1.7,1-2.4c0.5-0.7,1.2-1.2,2-1.5c1.1-0.4,2.2-0.6,3.3-0.6
			c1.2,0,2.3,0.1,3.5,0.4c1,0.2,1.9,0.5,2.8,0.9l1.1,0.5l0.3-1.2l1.7-7.6l0.2-0.9l-0.8-0.3C59.9-77.8,58.6-78.2,57.4-78.4"/>
		<path class="st1" d="M89.7-27.7l-0.2-1.2l-1.1,0.4c-1,0.4-2,0.7-3,1C84-27.1,82.7-27,81.3-27c-2.1,0-3.4-0.5-4.3-1.7
			c-0.8-1-1.1-3-1.1-6.2v-14.7H90v-10.1H75.9V-72l-1.2,0.2l-10.1,1.5l-0.9,0.1v35.3c0,2.4,0.4,4.9,1,7.3c0.5,2.1,1.5,4,2.8,5.6
			c1.3,1.6,3,2.9,5,3.6c2.4,0.9,4.9,1.3,7.5,1.2c2.1,0,4.3-0.2,6.4-0.8c1.5-0.4,2.9-0.9,4.3-1.4l0.7-0.3l-0.2-0.8L89.7-27.7z"/>
		<path class="st1" d="M118.5-48.7c0.6,0.8,1.1,1.7,1.4,2.7c0.3,0.8,0.4,1.6,0.5,2.5h-15c0.1-0.6,0.3-1.2,0.5-1.8l0.2-0.5
			c0.3-1,0.9-2,1.5-2.8c0.7-0.8,1.5-1.4,2.4-1.8c1-0.5,2.2-0.7,3.3-0.7c1.1,0,2.1,0.2,3.1,0.6C117.2-50,117.9-49.4,118.5-48.7
			 M113.1-60.8c-2.5,0-4.9,0.5-7.2,1.4c-2.4,0.9-4.5,2.4-6.3,4.2c-1.9,2-3.4,4.3-4.4,6.9c-1.1,3.1-1.7,6.3-1.6,9.6
			c0,2.9,0.4,5.8,1.3,8.6c0.8,2.6,2.1,4.9,3.9,6.9c1.9,2,4.2,3.6,6.8,4.6c3,1.1,6.3,1.7,9.5,1.6c1.4,0,2.8-0.1,4.2-0.2
			c1.3-0.2,2.6-0.3,3.8-0.6c1.1-0.2,2.1-0.5,3.1-0.8c0.8-0.2,1.5-0.5,2.2-0.9l0.7-0.3l-0.1-0.7l-1.2-7.6l-0.2-1.3l-1.2,0.5
			c-1.4,0.5-2.8,0.9-4.2,1.2c-1.9,0.4-3.9,0.6-5.9,0.5c-2.9,0.2-5.8-0.7-8.1-2.5c-1.8-1.4-2.8-3.6-2.9-5.9h25.2c1,0,1-0.7,1.1-2.4
			c0-0.7,0-1.1,0-1.6c0-7-1.6-12.3-4.9-15.9C123.4-58.9,118.9-60.8,113.1-60.8"/>
		<path class="st1" d="M163-59.2c-0.5-0.2-1.1-0.3-1.9-0.5c-0.8-0.2-1.6-0.3-2.5-0.5c-0.8-0.1-1.7-0.2-2.5-0.3
			c-0.8-0.1-1.7-0.1-2.4-0.1c-3.1,0-6.2,0.3-9.3,0.9c-3,0.6-5.3,1.3-7.2,1.9l-0.7,0.2v40.3h12.1v-32.8c0.3-0.1,0.6-0.1,0.9-0.2
			l0.7-0.1c1-0.2,2.1-0.3,3.1-0.3c1.5,0,3,0.1,4.5,0.4c1,0.2,2,0.5,3,0.8l1.1,0.4l0.2-1.1l1.6-7.8l0.2-0.9L163-59.2z"/>
	</g>
	<path class="st0" d="M199.6-55.2c-1.5-1.8-3.4-3.2-5.6-4c-2.7-1-5.6-1.5-8.4-1.4c-3.2,0-6.3,0.2-9.4,0.7c-2.5,0.4-4.2,0.8-5.9,1.3
		l-0.7,0.2l-0.8,0.2v41h10.1v-32.9c0.4,0,0.9-0.1,1.3-0.2c0.2,0,0.5-0.1,0.9-0.1c1.3-0.1,2.5-0.2,3.8-0.2c1.2,0,2.5,0.2,3.7,0.6
		c0.9,0.3,1.7,0.9,2.2,1.7c0.7,1,1.2,2.1,1.4,3.3c0.1,0.4,0.2,0.7,0.2,1.1c0.3,1.4,0.5,2.7,0.6,4.1v22.6H203v-24.1
		c0.1-2.6-0.1-5.1-0.6-7.6C201.9-51.2,201-53.4,199.6-55.2"/>
	<path class="st0" d="M219.3-17.3v-1v-40.3v-1h-1h-8.1h-1v1v40.3v1h1h8.1H219.3z"/>
	<path class="st0" d="M256.7-20.1l-1.3-7.6l-0.2-1.2l-1.2,0.5c-1.2,0.5-2.5,0.8-3.8,1.1c-1.5,0.3-3,0.4-4.4,0.4
		c-3.9,0-6.6-1-8.2-3.1c-1.8-2.6-2.6-5.7-2.5-8.8c0-3.8,0.9-6.8,2.6-8.9c1.7-2,4.1-3,7.4-3c1.4,0,2.7,0.1,4.1,0.4
		c1.2,0.3,2.3,0.6,3.4,1.1l1.1,0.4l0.3-1.1l2-7.5l0.2-0.8l-0.8-0.3c-3.5-1.4-7.2-2.1-11-2.1c-3,0-5.9,0.5-8.6,1.7
		c-2.5,1.1-4.7,2.7-6.5,4.7c-1.8,2-3.2,4.4-4.1,7c-1,2.8-1.4,5.7-1.4,8.6c0,2.9,0.4,5.8,1.3,8.6c0.8,2.6,2.1,5,3.9,7
		c1.8,2,4.1,3.6,6.6,4.6c2.9,1.1,6.1,1.7,9.2,1.6c2.2,0,4.4-0.2,6.6-0.6c1.7-0.3,3.3-0.8,4.9-1.5l0.7-0.3L256.7-20.1z"/>
	<path class="st2" d="M289.4-20.4l2.8-33.6l24.9,9.9l-8.8,7.6c11.7,15.5,23.1,20.1,32.5,20.3c17.6-14.4,20.1-40.4,5.7-58
		c-11.9-14.5-32.2-19.1-49.2-11.2c18,1.8,34.2,14.9,34.2,14.9l7.5-6.1L336.5-44l-24.3-9.6l8.5-7.4c-11.2-14.8-22.1-19.4-31.2-19.7
		c-18,13.8-21.4,39.7-7.6,57.7C294-7.1,315.6-2.3,333.3-11.3c-18.9-1.5-36.1-15.4-36.1-15.4L289.4-20.4z"/>
	<path class="st3" d="M320.7-60.9l-8.5,7.4l24.3,9.6l2.6-32.7l-7.5,6.1c0,0-16.2-13.1-34.2-14.9c-2.8,1.3-5.4,2.9-7.8,4.8
		C298.6-80.3,309.5-75.7,320.7-60.9"/>
	<path class="st3" d="M308.4-36.5l8.8-7.6L292.2-54l-2.8,33.6l7.8-6.3c0,0,17.2,13.9,36.1,15.4c2.7-1.4,5.3-3,7.6-4.9
		C331.5-16.4,320.1-21.1,308.4-36.5"/>
</g>
</svg></div>
</a>`,
    }
  }

  return <div dangerouslySetInnerHTML={AfternicActive()}></div>
}
