import React from "react"
import { StaticImage, GatsbyImage as Img } from "gatsby-plugin-image"
import { ThemeContext } from "./themeContext"

export default function Mockup(props) {
  const { theme, setTheme } = React.useContext(ThemeContext)
  return (
    <div>
      <div class="card sm:shadow-2xl mb-12 m-auto max-w-6xl 2xl:max-w-7xl sm:p-8 sm:bg-base-200 sm:bg-base-100">
        <div class="flex flex-wrap flex-col sm:flex-row -mx-2">
          <div class="my-2 px-2 md:w-1/3 relative">
            <StaticImage
              style={{
                height: "386px",
              }}
              class="bg-base-100 rounded-lg"
              src="../images/hand-phone.png"
              alt="mockup"
              objectFit="cover"
            />

            <div
              style={{
                position: "absolute",
                top: "29%",
                left: "30%",
                width: "45%",
              }}
              class="mockup-logo"
            >
              <Img alt="image" image={props.logo} />
            </div>
          </div>

          <div class="my-2 px-2 md:w-1/3 relative">
            <StaticImage
              style={{
                height: "386px",
              }}
              class="bg-base-100 rounded-lg"
              src="../images/macbook-clay.png"
              alt="mockup"
              objectFit="cover"
            />
            <div
              style={{
                position: "absolute",
                top: "38%",
                left: "22%",
                width: "57%",
                transform: "translateX(0%) translateY(-20%)",
              }}
              class="mockup-logo"
            >
              <Img alt="image" image={props.logo} />
            </div>
          </div>

          <div class="my-2 px-2 md:w-1/3 relative">
            <StaticImage
              style={{
                height: "386px",
              }}
              class="bg-base-100 rounded-lg"
              src="../images/cards.png"
              alt="mockup"
            />
            <div
              style={{
                position: "absolute",
                top: "52%",
                left: "47%",
                width: "28%",
                transform:
                  "translateX(-76%) translateY(-189%) rotate(25deg) skew(-5deg, -4deg)",
              }}
              class="mockup-logo"
            >
              <Img alt="image" image={props.logo} />
            </div>
            <div
              style={{
                position: "absolute",
                top: "58%",
                left: "57%",
                width: "41%",
                filter: "grayscale(1)",
                transform:
                  "translateX(-65%) translateY(-7%) rotate(-15deg) skew(-4deg, -4deg)",
              }}
              class="mockup-logo"
            >
              <Img alt="image" image={props.logo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
