import React, { useState } from "react"
import Price from "@components/Price"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import DanInstalmentButton from "../PaymentPlan/PayButtons/DanInstalmentButton"
import ReactSlider from "react-slider"
import "./style.css"

export default props => {
  const [checked, setChecked] = useState(false)
  const handleClick = () => setChecked(!checked)

  const [currentIndex, setCurrentIndex] = useState(6)
  const handleOnChange = index => {
    setCurrentIndex(index)
  }

  return (
    <div>
      <div class="flex-row items-center space-x-4 card-body pt-0 2xl:pt-2 pb-12 px-0">
        <div class="flex-1">
          <h2 class="card-title font-bold text-base sm:text-xl mb-0 text-base-content">
            Buy {props.title}
          </h2>{" "}
          <h2 class="text-base-content font-semibold text-2xl sm:text-3xl">
            <Price
              currencyLabel={false}
              postPrice={props.price / currentIndex}
            />
            /month{" "}
            <span class="2xl:text-xl text-base sm:font-semibold block sm:inline">
              for {currentIndex} months
            </span>
          </h2>
        </div>{" "}
        <div class="flex-0 pr-2 h-24">
          <div class="avatar absolute top-10 right-6 sm:right-10">
            <div class="mb-8 rounded-box logo w-24 h-24 ring ring-primary light:ring-offset-base-100 ring-offset-2">
              <Img
                style={{ width: "144px", left: "-25%", top: "5%" }}
                alt="image"
                objectFit="cover"
                image={props.logo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="slider mb-11 mt-3 h-4">
        <ReactSlider
          min={2}
          step={1}
          max={6}
          defaultValue={6}
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          renderThumb={(props, state) => (
            <div {...props}>
              <div
                class={`flex font-bold absolute -mt-12 ${
                  state.valueNow === 6 && "-right-2"
                }`}
              >
                <p class="text-base-content">{state.valueNow}</p>{" "}
                <span class="ml-1 text-base-content">Months</span>
              </div>
            </div>
          )}
          onChange={handleOnChange}
        />
      </div>
      <div class="py-8 flex flex-row items-center noSelect">
        <div class="flex-1">
          <div bis_skin_checked="1">
            <div class="font-bold inline text-base-content text-xl">
              First Instalment:
            </div>
            <div class="text-sm text-base text-gray-400">
              Full ownership after ${currentIndex} months
            </div>
          </div>
        </div>
        <div class="flex-0 mb-auto">
          <p class="text-base-content light:text-opacity-60 text-xl font-bold">
            <Price postPrice={props.price / currentIndex} />
          </p>
        </div>
      </div>
      <form>
        <div class="inline-flex space-x-1.5 items-center justify-start mb-4">
          <input
            type="checkbox"
            class="checkbox checkbox-md"
            required
            onClick={handleClick}
            checked={checked}
          />
          <div className="ml-3">
            <p className="text-sm sm:text-base text-gray-500 dark:text-base-content">
              I agree to the{" "}
              <a
                href="/terms"
                className="font-medium text-gray-700 text-base-content underline"
              >
                Terms
              </a>{" "}
              &{" "}
              <a
                href="/privacy"
                className="font-medium text-gray-700 text-base-content underline"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
        {checked ? (
          <div>
            <DanInstalmentButton
              postTitle={props.title}
              price={props.price / currentIndex}
              currentIndex={currentIndex}
            />
          </div>
        ) : (
          <div>
            <button class="btn btn-accent shadow hover:bg-gray-50 w-full">
              Pay <Price postPrice={props.price / currentIndex} /> with{" "}
              <div class="w-24 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 30">
                  <path
                    d="M18.4 7.7V.3h7.3v7.3h-7.3zM33.1 26c0 2-1.6 3.7-3.7 3.7-2 0-3.7-1.6-3.7-3.7 0-2 1.6-3.7 3.7-3.7 2 0 3.7 1.7 3.7 3.7zm-14.7-7.3V15h-3.7c-2 0-3.7 1.6-3.7 3.7 0 2 1.6 3.7 3.7 3.7 2-.1 3.7-1.7 3.7-3.7zm0-11V15h7.3v3.7c0 6.1-4.9 11-11 11s-11-4.9-11-11 4.9-11 11-11h3.7z"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#008c80"
                  ></path>
                  <path
                    d="M40.9 10.3h2.6c1.4 0 2.6.2 3.8 1 1.2.9 1.9 2.4 1.9 4.1 0 1.7-.7 3.1-2 4.1-1.2.8-2.3 1-3.7 1h-2.6V10.3zm2 1.7v6.7h.6c.5 0 1.6 0 2.5-.7.8-.6 1.3-1.6 1.3-2.7s-.4-2.1-1.2-2.7c-.8-.6-1.8-.7-2.5-.7-.1.1-.7.1-.7.1zm16.6 8.5l-.9-2.1h-4.1l-.9 2.1h-2.1l4.4-10.1h1.5l4.2 10.1h-2.1zm-4.3-3.8h2.9l-1.4-3.6-1.5 3.6zm12 3.8h-2V10.3h1.4l6.2 6.8v-6.8h2v10.1h-1.4l-6.2-6.8v6.9zm11.1-1c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.6-1.2-1.2zm13.8-1.7v2.3c-.6.3-1.4.6-2.5.6-1.8 0-2.9-.6-3.7-1.4-1.2-1.1-1.6-2.4-1.6-3.9 0-1.8.8-3.2 1.6-4 1-1 2.3-1.3 3.7-1.3.7 0 1.6.1 2.5.6V13c-.9-1.1-2.1-1.1-2.4-1.1-2 0-3.4 1.7-3.4 3.5 0 2.2 1.7 3.5 3.4 3.5 1 0 1.8-.4 2.4-1.1zm9.1-7.7c3.1 0 5.3 2.2 5.3 5.3 0 3.1-2.2 5.3-5.3 5.3-3.1 0-5.3-2.2-5.3-5.3 0-3.1 2.2-5.3 5.3-5.3zm0 8.6c1.9 0 3.2-1.4 3.2-3.3s-1.3-3.3-3.2-3.3c-1.9 0-3.2 1.4-3.2 3.3 0 2 1.3 3.3 3.2 3.3zm8.1 1.8l1.9-10.1h1.4l2.7 6.4 2.8-6.4h1.4l1.7 10.1h-2l-.9-6.2-2.7 6.2h-.6l-2.6-6.2-1 6.2h-2.1z"
                    fill="#444"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        )}
      </form>
      {/* <div>
        <div
          sx={{
            pl: ["0.75rem", 3],
            pt: ["10px", 2],
            width: ["43px", "52px"],
            flexDirection: "column",
          }}
        >
          <div dangerouslySetInnerHTML={PayButton()}></div>
        </div>
        Pay with Dan
      </div> */}
    </div>
  )
}
