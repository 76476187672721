import React, { useState } from "react"
import { StaticImage, GatsbyImage as Img } from "gatsby-plugin-image"
import Instalments from "./PaymentPlan/Instalments"
import BuyNow from "@components/PriceBox/BuyNow"
import MakeOffer from "./MakeOffer"
import Divider from "@components/Divider"
import Speech from "@components/LazyComponents/SpeechDelay"
import FavoriteCardIcon from "@components/Favorite/FavoriteCardIcon"

export default props => {
  const [active, setActive] = useState("")
  function handleChange(e) {
    setActive(e.target.value)
    setShowModal(true)
  }

  const [showModal, setShowModal] = useState(false)
  const closeModal = () => setShowModal(!showModal)

  function openChat() {
    window.chaport.q("open")
    return false
  }

  return (
    <div>
      {/* NEW CARD */}
      <div className="relative dark:bg-base-200">
        <main className="my-6 sm:my-16">
          <div className="mx-auto max-w-6xl 2xl:max-w-7xl">
            <div className="flex flex-col lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="sm:mt-24 lg:mt-0 lg:col-span-5">
                <div className="card shadow-2xl bg-base-100 m-auto sm:max-w-lg w-full sm:mx-auto">
                  <div className="px-4 py-8 sm:px-10">
                    {showModal ? (
                      <>
                        <button
                          class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-secondary rounded-full"
                          onClick={closeModal}
                        >
                          Back
                        </button>
                        {active === "buynow" && (
                          <BuyNow
                            offer={props.offer}
                            active={active}
                            price={props.price}
                            afternic={props.afternic}
                            title={props.title}
                            logo={props.logo}
                            dan={props.dan}
                            escrow={props.escrow}
                          />
                        )}
                        {active === "makeoffer" && (
                          <MakeOffer
                            price={props.price}
                            title={props.title}
                            logo={props.logo}
                          />
                        )}
                        {active === "instalments" && (
                          <Instalments
                            price={props.price}
                            title={props.title}
                            logo={props.logo}
                          />
                        )}
                      </>
                    ) : (
                      <div className="w-full max-w-md mx-auto">
                        <div className="cursor-pointer -mt-2 mr-2 sm:mr-0 sm:mt-0 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 justify-end flex">
                          <FavoriteCardIcon
                            name={props.title}
                            id={props.title}
                            price={props.price}
                            slug={props.slug}
                          />
                        </div>
                        <div class="text-center logo mb-3 noSelect">
                          <Img alt="image" image={props.logo} />
                        </div>
                        <div class="flex flex-row items-center mb-4 noSelect">
                          <div class="flex-1">
                            <h2 class="card-title text-lg mb-0 text-base-content">
                              Buy {props.title}
                            </h2>
                          </div>
                          <div class="flex-0">
                            <p class="text-base-content light:text-opacity-60 text-lg">
                              ${props.price} USD
                            </p>
                          </div>
                        </div>
                        {/* <PaymentSteps
                          price={props.price}
                          title={props.title}
                          logo={props.logo}
                        /> */}
                        <div>
                          <button
                            value="buynow"
                            onClick={handleChange}
                            type="submit"
                            className="btn btn-primary w-full mb-4"
                          >
                            Buy Now
                          </button>
                        </div>
                        {props.dan && (
                          <div>
                            <button
                              value="instalments"
                              onClick={handleChange}
                              type="submit"
                              className="btn w-full"
                            >
                              Pay in Instalments
                            </button>
                          </div>
                        )}
                        {props.offer && (
                          <>
                            <Divider />
                            <div>
                              <button
                                value="makeoffer"
                                onClick={handleChange}
                                type="submit"
                                className="btn btn-outline dark:bg-base-200 dark:text-base-content dark:border-gray-900 dark:hover:bg-black w-full"
                              >
                                Make Offer
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {/* Form */}
                    {/* <div className="mt-6">
                      <form action="#" method="POST" className="space-y-6">
                        <div>
                          <label htmlFor="name" className="sr-only">
                            Full name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autocomplete="name"
                            placeholder="Full name"
                            required=""
                            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                          ></input>
                        </div>

                        <div>
                          <label htmlFor="mobile-or-email" className="sr-only">
                            Mobile number or email
                          </label>
                          <input
                            type="text"
                            name="mobile-or-email"
                            id="mobile-or-email"
                            autocomplete="email"
                            placeholder="Mobile number or email"
                            required=""
                            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                          ></input>
                        </div>

                        <div>
                          <label htmlFor="password" className="sr-only">
                            Password
                          </label>
                          <input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            autocomplete="current-password"
                            required=""
                            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                          ></input>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Create your account
                          </button>
                        </div>
                      </form>
                    </div> */}
                  </div>
                  {/* <div className="px-4 py-6 bg-gray-50 dark:bg-base-100 border-t-2 border-gray-200 sm:px-10">
                    <p className="text-xs leading-5 text-gray-900 dark:text-gray-400">
                      By proceeding, you agree to our{" "}
                      <a
                        href="/"
                        className="font-medium text-gray-900 dark:text-gray-400 hover:underline"
                      >
                        Terms
                      </a>
                      , and{" "}
                      <a
                        href="/"
                        className="font-medium text-gray-900 dark:text-gray-400 hover:underline"
                      >
                        Cookies Policy
                      </a>
                      .
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="my-6 sm:my-0 card shadow-2xl bg-base-100 sm:text-center w-full md:mx-auto lg:col-span-7 lg:text-left lg:flex lg:items-center">
                <div class="card-body w-full justify-between">
                  <div class="flex flex-row w-full">
                    <div class="grid flex-grow w-3/5">
                      <h1 className="mb-4 text-3xl sm:text-4xl tracking-tight font-extrabold text-base-content sm:leading-none lg:text-5xl ">
                        <span
                          className="inline mr-4"
                          // style={{ fontFamily: props.font }}
                        >
                          {props.title}
                        </span>
                        {/* space */}
                        <span className="text-indigo-400 inline">
                          is for sale!
                        </span>
                      </h1>
                    </div>
                    {/* <div class="grid flex-grow mt-6">
                      <div class="flex flex-row w-full h-8">
                        <div class="grid flex-grow place-items-center text-3xl font-medium">
                          $4,799
                        </div>
                        <div class="divider divider-vertical text-sm opacity-60 mx-2">
                          Or
                        </div>
                        <div class="grid flex-grow place-items-center">
                          <div class="text-center">
                            <h2 class="card-title -mb-1.5">$400</h2>{" "}
                            <p class="text-base-content text-opacity-40">
                              Per month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <Speech title={props.title} /> */}
                  <div>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      {props.description}
                    </p>
                    <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                      {props.possible_uses}
                    </p>
                  </div>
                  {/* <div className="flex-1 py-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-base-100">
                        What's included
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-600"></div>
                    </div>
                    <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                      <li className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            data-todo-x-description="Heroicon name: solid/check-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <p className="ml-3 text-sm text-base-100">
                          Private forum access
                        </p>
                      </li>

                      <li className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            data-todo-x-description="Heroicon name: solid/check-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <p className="ml-3 text-sm text-base-100">
                          Member resources
                        </p>
                      </li>

                      <li className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            data-todo-x-description="Heroicon name: solid/check-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <p className="ml-3 text-sm text-base-100">
                          Entry to annual conference
                        </p>
                      </li>

                      <li className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            data-todo-x-description="Heroicon name: solid/check-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <p className="ml-3 text-sm text-base-100">
                          Official member t-shirt
                        </p>
                      </li>
                    </ul>
                  </div> */}
                  <div class="mt-6 text-lg font-extrabold text-base-content">
                    What you get?
                  </div>
                  <div class="sm:flex flex-wrap -mx-2">
                    <div class="my-2 px-2 sm:w-1/3">
                      <div class="inline-flex space-x-3 items-start justify-start w-full py-5 items-center">
                        <div class="inline-flex items-center justify-center p-3 bg-green-50 rounded-lg">
                          <svg
                            stroke="currentColor"
                            stroke-width="0"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                          >
                            <path
                              d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
                              fill="currentColor"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <div class="inline-flex flex-col items-start justify-start flex-1">
                          <p class="w-full text-base font-medium leading-tight text-base-content">
                            Domain Name
                          </p>
                          <p class="w-full text-sm leading-tight text-base text-gray-400">
                            {props.title}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="my-2 px-2 sm:w-1/3">
                      <div class="inline-flex space-x-3 items-start justify-start w-full py-5 items-center">
                        <div class="inline-flex items-center justify-center p-3 bg-green-50 rounded-lg">
                          <svg
                            stroke="currentColor"
                            stroke-width="0"
                            fill="bg-green-500"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                          >
                            <path
                              d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
                              fill="currentColor"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <div class="inline-flex flex-col items-start justify-start flex-1">
                          <p class="w-full text-base font-medium leading-tight text-base-content">
                            Logo Design
                          </p>
                          <p class="w-full text-sm leading-tight text-base text-gray-400">
                            Vector SVG file
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="my-2 px-2 sm:w-1/3">
                      <div class="inline-flex space-x-3 items-start justify-start w-full py-5 items-center">
                        <div class="inline-flex items-center justify-center p-3 bg-green-50 rounded-lg">
                          <svg
                            stroke="currentColor"
                            stroke-width="0"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                          >
                            <path
                              d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
                              fill="currentColor"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <div class="inline-flex flex-col items-start justify-start flex-1">
                          <p class="w-full text-base font-medium leading-tight text-base-content">
                            Free Transfer
                          </p>
                          <p class="w-full text-sm leading-tight text-base text-gray-400">
                            No hidden Fees
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sm:flex flex-wrap -mx-2">
                    <div class="sm:px-2 sm:w-1/2">
                      <div class="mb-3 sm:mb-0 inline-flex space-x-3 items-start w-full justify-start px-4 2xl:px-6 py-5 shadow border dark:border-transparent rounded-lg bg-base-200 ">
                        <Speech title={props.title} />
                        <div bis_skin_checked="1">
                          <div
                            class="font-bold inline text-base-content"
                            bis_skin_checked="1"
                          >
                            How to pronunce
                          </div>
                          <div
                            class="text-sm opacity-50 text-base text-gray-400"
                            bis_skin_checked="1"
                          >
                            "{props.title}"
                          </div>
                        </div>
                        {/* <div class="inline-flex flex-col items-start justify-start flex-1">
                          <p class="w-full text-base font-medium leading-tight text-base-content">
                            How to pronunce
                          </p>
                          <p class="w-full text-sm leading-tight text-base text-gray-400">
                            "{props.title}"
                          </p>
                        </div> */}
                      </div>
                    </div>

                    <div class="sm:px-2 sm:w-1/2">
                      <div class="inline-flex space-x-3 items-start justify-start w-full px-4 2xl:px-6 py-5 shadow border dark:border-transparent rounded-lg bg-base-200">
                        <div class="avatar online">
                          <div class="w-12 h-12 mask mask-squircle">
                            <StaticImage
                              src="../../images/Szabolcs-Sz.jpg"
                              alt="Domain Transfer Guide"
                            />
                          </div>
                        </div>
                        <div bis_skin_checked="1">
                          <div
                            class="font-bold inline text-base-content"
                            bis_skin_checked="1"
                          >
                            Szabolcs Sz.
                          </div>{" "}
                          <a
                            href="#"
                            onClick={openChat}
                            class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-secondary rounded-full"
                          >
                            Live Chat
                          </a>
                          <div
                            class="text-sm opacity-50 text-base text-gray-400"
                            bis_skin_checked="1"
                          >
                            "Domain transfer expert"
                          </div>
                        </div>
                        {/* <div class="inline-flex flex-col items-start justify-start flex-1">
                          <p class="w-full text-base font-medium leading-tight text-base-content">
                            Szabolcs Sz.
                          </p>
                          <p class="w-full text-sm leading-tight text-base text-gray-400">
                            "Expert domain transfer guide"
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const styles = {
  label: {
    position: "absolute",
  },
  labelWrapper: {
    flexDirection: "column",
    height: "4rem",
    justifyContent: "space-between",
  },
  labelList: {
    mb: 4,
  },
}
