import React from "react"
import { Disclosure, Transition } from "@headlessui/react"
import { FaChevronUp } from "react-icons/fa"
import data from "../../site-metadata.json"
import { markdownify } from "../utils"
import _ from "lodash"

export default () => {
  let options = _.get(data, "faq", null)
  return (
    <div class="z-10">
      <div className="" data-todo-x-data="{ openPanel: 0 }">
        <div className="max-w-6xl 2xl:max-w-7xl mx-auto pt-12 sm:pt-16">
          <p class="text-3xl font-extrabold leading-10 text-base-content">
            Have a question?
          </p>
          <div className="divide-y-2 divide-gray-200">
            <div className="w-full pt-12">
              <div className="w-full max-w-md p-4 pb-px mx-auto bg-base-100 rounded-2xl">
                {_.map(options, (item, item_idx) => (
                  <Disclosure
                    defaultOpen={false}
                    as="div"
                    class="mb-4"
                    key={item_idx}
                  >
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 sm:text-xl bg-base-200 text-base-content font-medium text-left rounded-lg hover:bg-base-300 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                          <span class="w-64 sm:w-full">
                            {_.get(item, "title", null)}
                          </span>
                          <FaChevronUp
                            className={`${
                              open ? "transform rotate-180" : ""
                            } w-5 h-5 text-base-content self-center`}
                          />
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-base-content">
                            {markdownify(_.get(item, "answer", null))}
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
